import React from 'react'
import './about.css'
import ME from '../../assets/me-about.jpg'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'

const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className='container about__container'>
        <div className='about__me'>
          <div className='about__me-image'>
            <img src={ME} alt="Tarek Ahamed" />
          </div>
        </div>

        <div className='about__content'>
          <div className='about__cards'>
            <article className='about__card'>
              <FaAward className='about__icon'/>
              <h5>Experience</h5>
              <small> 3+ Years</small>
            </article>
            <article className='about__card'>
              <FiUsers className='about__icon'/>
              <h5>Clients</h5>
              <small> 8 Clients Worldwide </small>
            </article>
            <article className='about__card'>
              <VscFolderLibrary className='about__icon'/>
              <h5>Projects</h5>
              <small>11 Projects</small>
            </article>
          </div>

          <p>
            <b>Multicloud DevOps Engineer</b> with three years of proven track record, excelling in <b>designing</b>, <b>supporting</b> and <b>automating</b> critical deployments across extensive infrastructure for eCommerce, HealthCare & Banking Systems. 
            Proficient in programming languages <b>(Java & Python)</b>, <b>Kubernetes</b>, <b>Jenkins</b>, <b>Cloud Infrastructure</b> and <b>Monitoring Tools</b>. 
            Adept at leveraging various DevOps tools and cloud technologies to drive automation, enhance teamwork, expedite project timelines and ensure the development of stable and versatile systems. 
            Beyond the tech realm, <b>Photography</b> is more than just a hobby to me; it's a creative pursuit that adds depth and richness to my life.
          </p>
        </div>
      </div> 
    </section>
  )
}

export default About